import indexStyles from '../sass/common.scss';
let commons = require('./utils/commons');
import faqJS from './includes/custom_faq.js';
import servicesBkgIMG from '../img/services-bkg.png';
import faqBkgIMG from '../img/faq-bkg.png';
import pricesBkgIMG from '../img/prices-bkg.png';
import actionsBkgIMG from '../img/actions-bkg.png';
import patientsInfoBkgIMG from '../img/patients-info-bkg.png';
import personelBkgIMG from '../img/personel-bkg.png';
import workExamplesBkgIMG from '../img/work-examples-bkg.png';
import aboutBkg3IMG from '../img/about-bkg-3.png';
import serviceImage1IMG from '../img/service-image-1.png';
import serviceImage2IMG from '../img/service-image-2.png';
import serviceImage3IMG from '../img/service-image-3.png';
import serviceImage4IMG from '../img/service-image-4.png';
import serviceImage5IMG from '../img/service-image-5.png';
import serviceImage6IMG from '../img/service-image-6.png';
import serviceImage7IMG from '../img/service-image-7.png';
import serviceImage8IMG from '../img/service-image-8.png';
import serviceImage9IMG from '../img/service-image-9.png';
import faqAskFormIMG from '../img/faq-ask-form.png';
import actions1IMG from '../img/actions-1.png';
import actionDetailIMG from '../img/action-detail.png';
import personelDetail1IMG from '../img/personel-detail-1.png';
import personelDetail2IMG from '../img/personel-detail-2.png';
import personelDetail3IMG from '../img/personel-detail-3.png';
import personelDetail4IMG from '../img/personel-detail-4.png';
import personelDetail5IMG from '../img/personel-detail-5.png';
import personelDetail6IMG from '../img/personel-detail-6.png';
import personel7IMG from '../img/personel-7.png';
import personel8IMG from '../img/personel-8.png';
import personel9IMG from '../img/personel-9.png';
import personel10IMG from '../img/personel-10.png';
import personel11IMG from '../img/personel-11.png';
import personel12IMG from '../img/personel-12.png';
import personel13IMG from '../img/personel-13.png';
import personel14IMG from '../img/personel-14.png';
import exampleBeforeIMG from '../img/example-before.png';
import exampleAftersIMG from '../img/example-after.png';
import about1IMG from '../img/about-1.png';
import about2IMG from '../img/about-2.png';
import about3IMG from '../img/about-3.png';
import about4IMG from '../img/about-4.png';
import about5IMG from '../img/about-5.png';
import about6IMG from '../img/about-6.png';
import about7IMG from '../img/about-7.png';
import about8IMG from '../img/about-8.png';
import about9IMG from '../img/about-9.png';
import about10IMG from '../img/about-10.png';
import personelFlatCard1IMG from '../img/personel-flat-card-1.png';
import personelFlatCard2IMG from '../img/personel-flat-card-2.png';
import personelFlatCard3IMG from '../img/personel-flat-card-3.png';
import personelFlatCard4IMG from '../img/personel-flat-card-4.png';
import personelFlatCard5IMG from '../img/personel-flat-card-5.png';
import personelFlatCard6IMG from '../img/personel-flat-card-6.png';
